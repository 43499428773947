/* You can add global styles to this file, and also import other style files */
@import '_variables';

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: $scrollbar-thumb-color;
  border-radius: 10px;
}

*::-webkit-scrollbar-track {
  background-color: $scrollbar-track-color;
  border-radius: 10px;
}

@font-face {
  font-family: 'Hiragino Kaku Gothic';
  /*a name to be used later*/
  src: url('./assets/fonts/Hiragino Kaku Gothic Std W8.otf');
  /*URL to font*/
}

@font-face {
  font-family: 'Meiryo';
  src: url('./assets/fonts/meiryo.ttc');
}

@font-face {
  font-family: "Meiryo";
  src: url('./assets/fonts/Meiryo-Bold.ttc');
  font-weight: bold;
}

html,
body {
  height: 100%;
  overflow: hidden;
}

body {
  font-family: $font-family !important;
  font-size: 0.875rem;
  color: $text-color;
  background-color: $background-color;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  display: block;
}

button {
  pre {
    font-family: $font-family !important;
  }
}

.p-component {
  font-family: $font-family !important;
}
.p-inputtext {
  font-family: $font-family !important;
}
.p-dialog-content {
  color: $text-color !important;
}

.p-orderlist .p-orderlist-list .p-orderlist-item.p-highlight,
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: $white-color !important;
  background: $primary-blue-color !important;
  .option-item {
    color: $white-color !important;
  }
}

.p-input-icon-right>i:last-of-type {
  color: $icon-default-color;

  &:hover {
    cursor: pointer;
    color: $icon-hover-color;
  }
}

input,
button,
textarea,
select {
  font: inherit;
}

h1 {
  font-size: 1.75rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.25rem;
}


i {
  height: 16px;
  width: 16px;
}

.list-item-default {
  height: 13px;
  width: 13px;
  .pi-times{
    color : $white-color;
    font-size: 9px;
    height: auto;
  }
}
//Custome primeng dialog
.p-dynamic-dialog,
.p-dialog {
  box-shadow: $common-modal-shadow;

  .p-dialog-header {
    height: 40px;
    display: flex;
    background-color: $dlg-header-color;
    color: $white-color;
    border-bottom-color: $dlg-header-color;

    .p-dialog-title {
      font-size: 0.875rem;
      font-weight: normal;
    }

    .p-dialog-header-icon {
      justify-content: flex-end;
      color: $white-color;
      min-width: 0;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .p-dialog-content {
    background: $dlg-background-color;
  }
}

.p-disabled, .p-component:disabled {
  cursor: not-allowed;
  opacity: 1;
  background: $disable-color !important;
}

.p-tooltip {
  font-size: $font-size-normal !important;
}

.p-datepicker .p-monthpicker .p-monthpicker-month {
  white-space: nowrap;
}

::-webkit-input-placeholder {
  color: $textbox-placeholder-color;
}

:host ::ng-deep .custom-spinner .p-progress-spinner-circle {
  animation: custom-progress-spinner-dash 1.5s ease-in-out infinite, custom-progress-spinner-color 6s ease-in-out infinite;
}

@media screen and (max-width: 640px) {
  .p-dialog {
     margin: 0 20px;
  }
}

pivot-chart {
  svg {

      .bb-axis .tick line,
      path.domain {
          stroke: $text-color !important;
      }

      tspan,
      text {
          fill: $text-color !important;
      }

      .bb-chart-arc text {
          fill: $white-color !important;
      }
  }

  .bb-tooltip td {
      background-color: $dlg-background-color;
  }
}

.zaitaku-chart {
  svg {

      .bb-axis .tick line,
      path.domain {
          stroke: $text-color !important;
      }

      tspan,
      text {
          fill: $text-color !important;
      }

      .bb-chart-arc text {
          fill: $white-color !important;
      }
  }

  .bb-tooltip td {
      background-color: $dlg-background-color;
  }
}

.p-dropdown-items {
  .option-item {
    width: 100% !important;
    color: var(--text-color);
    font-size: $font-size-normal;
  }
}

@keyframes custom-progress-spinner-color {

  100%,
  0% {
    stroke: #16697A;
  }

  40% {
    stroke: #489FB5;
  }

  66% {
    stroke: #82C0CC;
  }

  80%,
  90% {
    stroke: #FFA62B;
  }
}

@keyframes custom-progress-spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

body.dragging .node-text * {
	cursor: grabbing !important;
}

.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}


.d-flex {
  display: flex;
}

.f-col {
  flex-direction: column;
}

.justify-content-center {
  justify-content: center
}

.flex-direction-column {
  flex-direction: column
}

.align-items-center {
  align-items: center
}

.gap-10 {
 gap: 10px
}

.w-50 {
  width: 50%;
}
.w-100 {
  width: 100%;
}